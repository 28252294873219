import React from "react"
import * as styles from "./Footline.module.scss"
import content from "../../content/Footline.yaml"

export default () => (
  <div className={styles.footline}>
    {content.footline1}
    <br/>
    {content.footline2}
  </div>
)
