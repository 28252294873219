import React from "react"
import * as styles from "./Icon.module.scss"
import content from "../../content/Icon.yaml"

import { config, library } from "@fortawesome/fontawesome-svg-core"
import { faLinkedin, faGithub, faTwitter, faFacebook, faMedium } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

config.autoAddCss = false
library.add(faLinkedin, faGithub, faTwitter, faFacebook, faMedium)

export default () => (
  <>
    <div className={`${styles.icon} ${styles.iconLinkedin}`}>
      <a href={content.iconUrlLinkedin} target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon={faLinkedin} />
      </a>
    </div>
    <div className={`${styles.icon} ${styles.iconGithub}`}>
      <a href={content.iconUrlGithub} target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon={faGithub} />
      </a>
    </div>
    <div className={`${styles.icon} ${styles.iconTwitter}`}>
      <a href={content.iconUrlTwitter} target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon={faTwitter} />
      </a>
    </div>
    <div className={`${styles.icon} ${styles.iconFacebook}`}>
      <a href={content.iconUrlFacebook} target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon={faFacebook} />
      </a>
    </div>
    <div className={`${styles.icon} ${styles.iconMedium}`}>
      {/* <a href={content.iconUrlMedium} target="_blank" rel="noreferrer"></a> */}
      <FontAwesomeIcon icon={faMedium} />
    </div>
    <div className={`${styles.icon} ${styles.iconEi}`}>
      &#x82f1;
    </div>
  </>
)
