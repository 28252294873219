import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import * as styles from "./Portrait.module.scss"

import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"

export default () => {
  const { placeholderImage } = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "eiichiro.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED)
        }
      }
    }
  `)
  const pluginImage = getImage(placeholderImage)
  return <BgImage image={pluginImage} className={styles.portrait} />
}
