import React from "react"
import * as styles from "./Introduction.module.scss"
import content from "../../content/Introduction.yaml"

export default () => (
  <div className={styles.introduction}>
    {content.introduction1}
    <a href={content.introductionUrlAws} target="_blank" rel="noreferrer">{content.introduction2}</a>
    {content.introduction3}
  </div>
)
