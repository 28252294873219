import React from "react"
import Helmet from "react-helmet"
import Container from "../components/Container"
import Portrait from "../components/Portrait"
import Headline from "../components/Headline"
import Introduction from "../components/Introduction"
import Icon from "../components/Icon"
import Footline from "../components/Footline"
import Blank from "../components/Blank"
import content from "../../content/index.yaml"

export default () => (
  <>
    <Helmet>
      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width,initial-scale=1" />
      <title>{content.title}</title>
      <meta name="description" content={content.description} />
      <link rel="shortcut icon" href="/favicon.ico"></link>
    </Helmet>
    <Container>
      <Portrait />
      <Headline />
      <Introduction />
      <Icon />
      <Footline />
      <Blank />
    </Container>
  </>
)
